.downloads-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .download-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .download-item h3 {
    margin-top: 0;
    color: #2c3e50;
  }
  
  .download-button {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #2980b9;
  }