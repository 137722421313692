/* App.css */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.App-header {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 20px;
}

.App-header h1 {
  color: #ecf0f1;
  margin: 0;
  font-size: 2.5em;
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

nav ul li a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #3498db;
}

main {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* ... rest of your existing CSS ... */