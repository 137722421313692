.contact-container {
    padding: 20px;
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
  }
  
  p {
    font-size: 1rem;
    color: #555;
  }
  