.geo-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .slide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* This will make the container 80% of the viewport height */
  }
  
  .slide-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    z-index: 1;
  }
  
  .slick-prev {
    left: 20px;
  }
  
  .slick-next {
    right: 20px;
  }
  
  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .slick-dots {
    bottom: 20px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: white;
  }
  
  .slick-dots li.slick-active button:before {
    color: #3498db;
  }